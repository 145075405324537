@font-face {
  font-family: Robot-black;
  src: url('/Roboto-Black.ttf');
}

@font-face {
  font-family: Roboto-Medium;
  src: url('/Roboto-Medium.ttf');
}

@font-face {
  font-family: Robot-Regular;
  src: url('/Roboto-Regular.ttf');
}

body {
  min-height: 100vh;
}

.body {
  height: 100vh;
  position: relative;
  padding-top: 64px;
  padding-left: 200px;
  box-sizing: border-box;
}

.grid-box {
  padding: 24px;
}

.filter {
  position: relative;

  &-actions {
    position: absolute;
    top: 0;
    right: 0;
  }

  .filter-control {
    margin-right: 20px;
  }
}

.data-table {
  .on-data {
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    font-size: 16px;
    color: #666;
  }
}

.delete-msg {
  color: rgb(230, 0, 0);
}

.header-menu {
  &-text {
    margin-left: 10px;
  }
}

.page-map {
  padding-bottom: 20px;

  svg {
    vertical-align: middle;
  }
}

.pages {
  position: relative;

  .page-actions {
    position: absolute;
    top: -45px;
    right: 0;
    text-align: right;
    
    button {
      margin-left: 10px;
    }
  }
  
  .tabs-box {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }
}


.data-table {
  min-height: 150px;
  position: relative;

  .table-loading {
    position: absolute;
    top: 50%;
    left: calc(50% - 12px);
  }

  .actions {
    button {
      margin: 0 5px;
    }
  }

  &-detail {
    position: relative;

    &-action {
      position: absolute;
      top: 0;
      right: 0;;
    }
  }
}

body {
  .SingleDatePickerInput__withBorder {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    overflow: visible;
    padding: 5px;
    padding-bottom: 3px;
  }
  
  .SingleDatePicker_picker {
    z-index: 100;
  }
  
  .DateInput {
    width: initial;
  }
  
  .DateInput_input {
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    width: inherit;
    padding: 5px;
  }
}

